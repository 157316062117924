jQuery(window).on("load", function () {

    if ($("header .menu").offset().top > 100) {
        setTimeout(function () {
            $('header .menu').addClass('menu-up menu-down');
        }, 100);
    }

    $('.partners-block .slider, .editors .slider').css('opacity', '1');

      [].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
        img.setAttribute('src', img.getAttribute('data-src'));
        img.onload = function () {
            img.removeAttribute('data-src');
        };
    });

    if ($(window).width() < 1200) {
        $(window).scroll(function (event) {
            $('#map').removeClass('d-none');
        });

    } else {

        $('#map').removeClass('d-none');
    }



});

$(document).ready(function () {

    $("#menu").mmenu({
        "extensions": [
                "position-right",
                "pagedim-black",
                "border-offset"
             ],
        classNames: {
            fixedElements: {
                fixed: "fix-menu"
            },
            vertical: "expand"
        }
    });

    $("#menu").mhead();

    var api = $('#menu').data('mmenu');
    api.bind("close:finish", function () {
        $('.icon').removeClass('open');
    });

    $(".mm-listitem").click(
        function (evnt) {
            //evnt.preventDefault();
            api.close();
            $('.icon').removeClass('open');
        }
    )

    $('.jarallax').jarallax({
        speed: 0.2
    });

    $('.partners-block .slider, .editors .slider').slick({
        infinite: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
          ]
    });

    if ($(window).width() < 1200) {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st > $(window).height()) {
                $('.fix-menu').addClass('mh-scrolledout');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }
            if (st > lastScrollTop) {
                $('.fix-menu').addClass('menu-down');
            } else if (st == 0) {
                $('header').removeClass('menu-down');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }

            lastScrollTop = st;

        });

    } else {

        var heightHeader = $('header .container').height();
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();

            if (st > $(window).height()) {
                $('header .menu').addClass('menu-up');
            } else {
                $('header .menu').removeClass('menu-up');
            }
            if (st > heightHeader) {
                $('header .menu').addClass('menu-down');
            } else {
                $('header .menu').removeClass('menu-down');
            }
            if (st < lastScrollTop) {
                $('header .menu').removeClass('menu-up');
            }

            lastScrollTop = st;
        });
    }

    if ($('.contact-block').length) {
        $('.scroll').on("click", function (event) {
            event.preventDefault();
            var anchor = '#' + $(this).attr('href').split('#')[1];
            $('body,html').animate({
                scrollTop: $(anchor).offset().top
            }, 800);
        });
    }



    $('.contact-block-form').validate({
        submitHandler: function (form) {
            $('.drop label').remove()
            $('.contact-block-form').ajaxSubmit({
                clearForm: true,
                success: function () {
                    $('.toast-body').css('color', '#28a745');
                    $('.toast-body').text($('.contact-block-form').data('success'));
                    $('.toast').toast('show');
                    $('.contact-block-form').trigger('reset');
                },
                error: function () {
                    $('.toast-body').css('color', '#F03E30');
                    $('.toast-body').text($('.contact-block-form ').data('error'));
                    $('.toast').toast('show');
                }
            });

        }
    });

    $('#formModal .modal-form').validate({
        submitHandler: function (form) {
            $('.drop label').remove()
            $('#formModal .modal-form').ajaxSubmit({
                clearForm: true,
                success: function (data) {
                    if (data == 'success') {
                        $('.toast-body').css('color', '#28a745');
                        $('.toast-body').text($('.modal-form').data('success'));
                        $('.toast').toast('show');
                        $('.modal-form').trigger('reset');
                        $('#formModal').modal('hide');
                    } else {
                        $('.toast-body').css('color', '#F03E30');
                        $('.toast-body').text($('.modal-form ').data('error'));
                        $('.toast').toast('show');
                    }
                },
                error: function () {
                    $('.toast-body').css('color', '#F03E30');
                    $('.toast-body').text($('.modal-form ').data('error'));
                    $('.toast').toast('show');
                    $('#formModal').modal('hide');
                }
            });
        }
    });

    var location = window.location.href;
    var cur_url = location.split('/').pop();
    $('header ul li').each(function () {
        var link = $(this).find('a').attr('href');

        if (cur_url == link) {
            $(this).addClass('current');
        }
    });

    $('.lang.mm-listitem a').each(function () {
        var link = $(this).attr('href');
        if (cur_url == link) {
            $(this).addClass('current');
        }
    });

    $('.lang .wrap').click(function () {
        $(this).next().toggleClass('show');
        $(this).toggleClass('show');
    });

    $(document).mouseup(function (e) {
        var container = $(".lang");
        if ($(".lang").has(e.target).length === 0) {
            $('.lang ul').removeClass('show');
            $('.lang .wrap').removeClass('show');
        }
    });

    jQuery('textarea').autoResize();

    $('button[data-toggle|="modal"]').click(function () {
        var type = $(this).attr('data-action');
        $('form input[name="action"]').val(type);
    });

    $('button[data-drop|="yes"]').click(function () {
        $(
            '<div class="dropdown" id="dropdown">' +
            '<button class="btn btn-secondary dropdown-toggle w-100 bg-transparent text-text" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>' +
            '<div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton"></div>' +
            '</div>').appendTo(".modal form");
        $(".btn-submit").appendTo(".modal form");
        $("#dropdown button").text($(this).attr('data-title'));
        var valueArr = $(this).attr('data-value').split(',');
        var valueTitleArr = $(this).attr('data-value-title').split(',');
        for (var i = 0; i < valueArr.length; i++) {
            $('<span class="dropdown-item" data-value="' + valueArr[i] + '">' + valueTitleArr[i] + '</span>').appendTo(".dropdown-menu");
        }
    });

    $('.modal-form').on('click', '.dropdown-item', function () {
        var text = $(this).text();
        $(this).parents('.dropdown').find('button').text(text);
        $('form input[name="value_list"]').val($(this).attr('data-value'));
    });

    $('#formModal').on('hidden.bs.modal', function () {
        $('form input[name="value_list"]').val('');
        $('#dropdown').remove();
        $('.menu').removeClass('active');
    });
    $('#formModal').on('show.bs.modal', function () {
        $('.menu').addClass('active');
    });

    $('button[data-courses]').click(function () {
        var courses = $(this).attr('data-courses');
        $('form input[name="value_list"]').val(courses);
    });

    $('button[data-clubs]').click(function () {
        var clubs = $(this).attr('data-clubs');
        $('form input[name="value_list"]').val(clubs);
    });

});
